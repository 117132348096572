var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto col-10"},[_c('v-card-title',[_vm._v(" Transactions "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"sort-by":"date","search":_vm.search,"headers":_vm.headers,"items":_vm.sharedState.transactions,"items-per-page":15,"loading":_vm.sharedState.loading},scopedSlots:_vm._u([{key:"item.account",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account_id.name)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendAccountToEdit(item.account_id)}}},[_vm._v("mdi-pencil")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("neatDate")(item.date)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("neatNumber")(item.amount)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendToEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteTrans(item.id)}}},[_vm._v("mdi-delete")])]}},{key:"item.category_id.name",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"disabled":item.category_id.name ? true : false,"append-icon":item.category_id.name ? '' : '$dropdown',"items":_vm.sharedState.categories,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.updateCategory(item)}},model:{value:(item.category_id.id),callback:function ($$v) {_vm.$set(item.category_id, "id", $$v)},expression:"item.category_id.id"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }